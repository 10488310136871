/*-------------------------
    Post
-------------------------*/
.videoPlayer-1 {
    width: 100%;
    height: 100%;
    position: relative;
    background-size: cover;
    top: 0;
    left: 0;
    z-index: 0;
    @media (max-width: 991px) {
        height: 400px;
    }
    @media (max-width: 767px) {
        height: 300px;
    }
}

.post-box-layout1 {
    height: 100%;
    position: relative;
    z-index: 1;
    .content-holder {
        padding: 40px;
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 0;
        right: 0;
        @media (max-width: 767px) {
            padding: 30px;
        }
    }
}

.post-box-layout2 {
    padding-bottom: 65px;
    margin-bottom: 6px;
    .figure-holder {
        margin-bottom: 15px;
    }
    .content-holder {
        .entry-title {
            margin-bottom: 7px;
        }
        .entry-description {
            margin-bottom: 13px;
        }
    }
}

.post-box-layout3 {
    position: relative;
    .content-holder {
        padding: 20px 20px 12px;
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 0;
        right: 0;
        .entry-title {
            margin-bottom: 5px;
        }
    }
}

.post-box-layout4 {
    .figure-holder {
        margin-bottom: 15px;
    }
    .content-holder {
        .entry-title {
            margin-bottom: 12px;
        }
        .entry-description {
            margin-bottom: 23px;
        }
        .entry-meta {
            margin-bottom: 20px;
        }
    }
}

.post-box-layout5 {
    position: relative;
    z-index: 1;
    .content-holder {
        padding: 40px;
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 0;
        right: 0;
    }
}

.post-box-layout6 {
    .figure-holder {
        margin-bottom: 15px;
    }
    .content-holder {
        .entry-title {
            margin-bottom: 12px;
        }
    }
}

.post-box-layout7 {
    .figure-holder {
        margin-bottom: 15px;
    }
    .content-holder {
        border-bottom: 1px solid;
        border-color: var(--color-border-dark-1);
        margin-bottom: 18px;
        padding-bottom: 25px;
        &:last-child {
            border-bottom: 0;
            margin-bottom: 0;
            padding-bottom: 0;
        }
        .entry-title {
            margin-bottom: 13px;
        }
    }
}

.post-box-layout8 {
    display: flex;
    align-items: center;
    gap: 20px;
    .figure-holder {
        position: relative;
        max-width: 140px;
        width: 100%;
    }
    .content-holder {
        flex: 1;
        .entry-title {
            margin-bottom: 10px;
        }
    }
}

.post-box-layout9 {
    display: flex;
    align-items: center;
    column-gap: 30px;
    height: 100%;    
    .figure-holder {
        position: relative;
        max-width: 285px;
        width: 100%;
        height: 100%;
        .link-wrap {
            height: 100%;
        }
        img {
            height: 100%;
            object-fit: cover;
        }
    }        
    .content-holder {
        display: flex;
        align-items: center;
        .entry-title {
            margin-bottom: 10px;
        }
    }
    @media (max-width: 767px) {
        column-gap: 20px;
    } 
    @media (max-width: 575px) {
        height: auto;
        flex-direction: column;
        .figure-holder {
            max-width: inherit;
            height: auto;
            margin-bottom: 20px;
        }
    }
}

.post-box-layout10 {
    position: relative;
    z-index: 1;    
    .content-holder {
        padding: 40px;
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 0;
        right: 0;
        .entry-title {
            margin-bottom: 0;
        }
    }
}

.post-box-layout11 {
    .figure-holder {
        margin-bottom: 15px;
    }
    .content-holder {
        .entry-title {
            margin-bottom: 0;
        }
    }
}

.post-box-layout12 {
    .single-item {
        &:first-child {
            margin-bottom: 25px;
            .figure-holder {
                margin-bottom: 15px;
            }
            .content-holder {
                .entry-title {
                    margin-bottom: 8px;
                }
                .entry-description {
                    margin-bottom: 10px;
                }
            }
        }
        &:not(:first-child) {
            display: flex;
            align-items: center;
            gap: 16px;
            border-color: var(--color-border-dark-1-fixed);
            border-bottom: 1px solid;
            padding-bottom: 20px;
            margin-bottom: 20px;
            @media (min-width: 992px) and (max-width: 1199px) {
                display: block;
            }
            .figure-holder {
                position: relative;
                max-width: 160px;
                width: 100%;
                .figure-overlay:before {
                    display: none;
                }
                @media (min-width: 992px) and (max-width: 1199px) {
                    max-width: inherit;
                    margin-bottom: 15px;
                }
                @media (max-width: 480px) {
                    max-width: 110px;
                }
            }
            .content-holder {
                flex: 1;
                .entry-title {
                    font-size: 16px;
                    margin-bottom: 0;
                }                
                .entry-category {
                    @media (min-width: 992px) and (max-width: 1199px) {
                        margin-bottom: 5px;
                    }
                    @media (max-width: 480px) {
                        margin-bottom: 5px;
                    }
                }
            }
        }
        &:last-child {
            border-bottom: 0;
            margin-bottom: 0;
            padding-bottom: 0;
        }
        &.no-divider {
            &:not(:first-child) {
                border-bottom: 0;
                padding-bottom: 0;
            }
        }
    }
}

.post-box-layout13 {
    display: flex;
    align-items: center;
    gap: 30px;
    height: 100%;
    @media (max-width: 1199px) {
        gap: 20px;
    }
    @media (max-width: 991px) {
        display: block;
        height: auto;
    }
    .figure-holder {
        position: relative;
        max-width: 410px;
        width: 100%;
        height: 100%;
        @media (max-width: 991px) {
            height: auto;
            margin-bottom: 20px;
            max-width: inherit;
        }
        .link-wrap {
            height: 100%;
            img {
                height: 100%;
                object-fit: cover;
            }
        }
    }
    .content-holder {
        display: flex;
        align-items: center;
        height: 100%;
        padding-right: 30px;
        .entry-category {
            margin-bottom: 16px;
        }
        .entry-title {
            margin-bottom: 17px;
        }
        .entry-description {
            margin-bottom: 18px;
        }
        .entry-meta {
            margin-bottom: 30px;
        }
        @media (max-width: 1199px) {
            padding-right: 0;
        }
    }
}

.post-box-layout14 {
    position: relative;
    z-index: 1;
    .content-holder {
        padding: 30px;
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 0;
        right: 0;
        @media (max-width: 575px) {
            padding: 20px;
        }
        .entry-title {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;  
            overflow: hidden;
        }
    }
}

.post-box-layout15 {
    display: flex;
    align-items: center;
    column-gap: 16px;
    .figure-holder {
        width: 100px;
        border-radius: 50%;
        img {
            border-radius: 50%;
        }
    }
    .content-holder {
        flex: 1;
        .entry-title {
            margin-bottom: 10px;
        }
    }
}

.post-box-layout16 {
    position: relative;
    z-index: 1;
    .content-holder {
        padding: 24px;
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 0;
        right: 0;
        .entry-title {
            margin-bottom: 0;
        }
        @media (max-width: 575px) {
            padding: 20px;
        }
    }
}

.post-box-layout17 {
    min-height: 600px;
    position: relative;
    z-index: 1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    .content-holder {
        background-color: var(--color-light-1);
        max-width: 700px;
        position: absolute;
        bottom: 40px;
        transform: translateY(-100px);
        opacity: 0;
        visibility: hidden;
        left: 40px;
        right: 40px;
        padding: 30px;
        border-radius: 8px; 
        transition: var(--transition-slider);
        transition-delay: 0.4s;
        @media (max-width: 575px) {
            left: 15px;
            right: 15px;
            bottom: 15px;
            padding: 20px;
        }
    }
    &.slick-active {
        .content-holder {
            opacity: 1;
            visibility: visible;
            transform: translateY(0);
        }
    }
}

.post-box-layout18 {
    .figure-holder {
        margin-bottom: 16px;
    }
    .content-holder {
        .entry-title {
            margin-bottom: 10px;
        }
    }
}

.post-box-layout19 {
    margin-bottom: 80px;
    @media (max-width: 767px) {
        margin-bottom: 50px;
    } 
    &:last-child {
        margin-bottom: 0;
    }
    .entry-meta {
        margin-bottom: 12px;
    }   
    .entry-title {
        margin-bottom: 28px;
    }
    .figure-holder {
        margin-bottom: 17px;
    }
    .entry-description {
        margin-bottom: 23px;
    }
    .post-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid;
        border-color: var(--color-border-dark-1);
        padding-top: 15px;
        margin-top: 35px;
    }
}

.post-box-layout20 {
    display: flex;
    align-items: center;
    gap: 30px;
    height: 100%;
    @media (max-width: 767px) {
        column-gap: 20px;
    } 
    .figure-holder {
        height: 100%;
        position: relative;
        max-width: 360px;
        width: 100%;
        @media (max-width: 1199px) {
            flex: 1;
        } 
        .link-wrap {
            height: 100%;
        }
        img {
            height: 100%;
            object-fit: cover;
        }
    }
    @media (max-width: 575px) {
        display: block;
        .figure-holder {
            max-width: inherit;
            height: auto;
            margin-bottom: 20px;
        }
    } 
    .content-holder {
        display: flex;
        align-items: center;
        @media (max-width: 1199px) {
            flex: 1;
        } 
        .entry-title {
            margin-bottom: 10px;
        }
        .entry-description {
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;  
            overflow: hidden;
        }
    }
}

.post-box-layout21 {
    position: relative;
    z-index: 1;
    .content-holder {
        padding: 30px;
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 0;
        right: 0;
        .entry-title {
            margin-bottom: 15px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;  
            overflow: hidden;
        }
    }
}

.post-box-layout22 {
    display: flex;
    align-items: center;
    column-gap: 20px;
    height: 100%;    
    .figure-holder {
        position: relative;
        max-width: 150px;
        width: 100%;
        height: 100%;
        .link-wrap {
            height: 100%;
        }
        img {
            height: 100%;
            object-fit: cover;
        }
    }        
    .content-holder {
        display: flex;
        align-items: center;
        .entry-title {
            margin-bottom: 10px;
        }
    }
    @media (max-width: 767px) {
        column-gap: 15px;
    } 
    @media (max-width: 575px) {
        height: auto;
        flex-direction: column;
        .figure-holder {
            max-width: inherit;
            height: auto;
            margin-bottom: 15px;
        }
    }
}

.post-box-layout23 {
    display: flex;
    align-items: center;
    column-gap: 20px;
    .figure-holder {
        max-width: 120px;
        width: 100%;
        position: relative;
        z-index: 1;
    }
    .content-holder {
        .entry-title {
            margin-bottom: 10px;
        }
    }
}

.multi-posts-layout1 {
    .nav {
        padding-bottom: 5px;
        border-bottom: 1px solid;
        border-color: var(--color-border-dark-1-fixed);
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        column-gap: 20px;
        margin-top: 20px;
        margin-bottom: 30px;
        .nav-item {
            .nav-link {
                color: var(--color-dark-1-fixed);
                padding: 10px 0 8px;
                border: 0;
                font-family: var(--font-secondary);
                font-size: 22px;
                font-weight: 600;
                position: relative;
                z-index: 1;
                &:before {
                    content: "";                    
                    height: 12px;
                    width: 0;
                    position: absolute;
                    z-index: -1;
                    bottom: 10px;
                    left: 0;
                    background-color: var(--color-aquamarine);
                    transition: var(--transition);
                }
                &.active {
                    background-color: transparent;
                    &:before {
                        width: 100%;
                    }
                }
            }
        }
    }
}

.multi-posts-layout2 {
    .nav {  
        border: 0;      
        .nav-item {
            counter-increment: css-counter 1;  
            margin-bottom: 50px;
            @media (max-width: 991px) {
                margin-bottom: 30px;                        
            }
            @media (max-width: 767px) {
                margin-bottom: 25px;                        
            }
            &:last-child {
                margin-bottom: 0;
            }          
            .nav-link {
                padding: 0 0 0 58px;
                opacity: 0.6;
                border: 0;
                color: var(--color-meta-dark-1);
                font-size: 18px;
                font-weight: 600;
                position: relative;                
                transition: var(--transition);
                &:before {
                    opacity: 0.6;
                    content: counter(css-counter);
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    line-height: 1;
                    font-size: 65px;
                    color: var(--color-meta-dark-1);
                    transition: var(--transition);
                    @media (max-width: 1199px) {
                        font-size: 50px;
                    }
                    @media (max-width: 991px) {
                        font-size: 40px;                        
                    }
                }
                span {
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;  
                    overflow: hidden;
                }
                @media (max-width: 1199px) {
                    padding: 0 0 0 42px;
                }
                &.active {
                    opacity: 1;
                    color: var(--color-dark-1);
                    background-color: transparent;
                    &:before {
                        opacity: 1;
                        color: var(--color-dark-1);
                    }
                }
            }
        }
    }
}

.multi-posts-layout3 {
    .nav {
        display: flex;
        align-items: center;
        column-gap: 40px;
        border: 0;
        margin-bottom: 30px;
        .nav-item {
            display: inline-block;
            width: calc(50% - 20px);
            border: 0;
            .nav-link {
                border: 1px solid;
                border-color: var(--color-border-dark-1-fixed);
                color: var(--color-dark-1-fixed);
                border-radius: 45px;
                font-size: 18px;
                font-weight: 600;
                text-align: center;
                padding: 5px 20px;
                height: 44px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: var(--color-btn-bg);
                transition: var(--transition);
                &.active {
                    background-color: var(--color-light-1-fixed);
                    transform: translateX(-3px) translateY(-3px);
                    box-shadow: 3px 3px 0px 0px var(--color-border-dark-1-fixed);
                }
                &:hover {
                    background-color: var(--color-light-1-fixed);
                }
            }
        }
    }
    .tab-content {
        .tab-pane {
            >div {
                margin-bottom: 16px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.multi-posts-layout4 {
    >div {
        margin-bottom: 30px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    .post-box {
        padding: 16px;
    }
}

.multi-posts-layout5 {
    .post-box {
        &:first-child {
            position: relative;
            z-index: 1;
            .figure-holder {
                margin-bottom: 20px;
            }
            .content-holder {
                position: absolute;
                z-index: 1;
                left: 0;
                bottom: 0;
                padding: 30px;
                @media (max-width: 575px) {
                    padding: 20px;
                }
            }
        }
        &:not(:first-child) {
            padding: 0 20px;
            .content-holder {
                border-bottom: 1px solid;
                border-color: var(--color-border-dark-2);
                padding-bottom: 22px;
                margin-bottom: 24px;
                .entry-title {
                    margin-bottom: 18px;
                }
            }
        }
        &:last-child {
            .content-holder {
                border-bottom: 0;
                margin-bottom: 0;
                padding-bottom: 20px;
            }
        }
    }
}