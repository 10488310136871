/*-------------------------
    Single Post
-------------------------*/
.single-blog-content { 
    .video-post-wrap {
        margin-bottom: 20px;
        .video-box {
            max-width: 100%;
            width: 100%;
            border-radius: 8px;
            border: 1px solid;
            border-color: var(--color-border-dark-1);
        }
    }

    .audio-post-wrap {
        margin-bottom: 30px;
        border-radius: 8px;
        overflow: hidden;
        border: 1px solid;
        border-color: var(--color-border-dark-1);
        iframe {
            width: 100%;
        }
    }

    .entry-meta {
        margin-bottom: 20px;
    } 

    .axil-social {
        margin-bottom: 30px;
    }

    .list-style-1 {
        padding: 0;
        margin: 0 0 25px 0;
        list-style: none;

        li {
            max-width: 785px;
            width: 100%;
            padding-left: 25px;
            margin-bottom: 10px;
            position: relative;

            &:last-child {
                margin-bottom: 0;
            }

            &:before {
                content: "\ed8e";
                font-family: 'icomoon' !important;
                position: absolute;
                left: 0;
                top: 4px;
                line-height: 1;
                color: var(--color-body);
                font-weight: 500;
            }
        }
    }

    .figure-caption {
        margin-top: 10px;
        color: var(--color-body);
    }

    blockquote {        
        padding: 80px 30px 30px 30px;
        margin-bottom: 20px;
        position: relative;
        background-color: var(--color-old-lace);
        border-radius: 8px;        
        &:before {
            content: "\ec33";
            font-family: var(--font-icomoon);
            position: absolute;
            left: 24px;
            font-weight: 500;
            top: 6px;
            font-size: 70px;
            line-height: 1;
            opacity: 0.3;
            color: var(--color-body-fixed);
        }
        &:after {
            border-radius: 8px 0 0 8px;
            content: "";
            background-color: var(--color-perano);
            height: 100%;
            width: 5px;
            position: absolute;
            left: 0;
            top: 0;
        }
        p {
            font-family: var(--font-secondary);
            font-size: 18px;
            font-style: italic;
            font-weight: 500;
            margin-bottom: 10px;
            color: var(--color-body-fixed);
        }
        cite {
            position: relative; 
            padding-left: 40px;
            font-style: normal;
            font-size: 15px;
            color: var(--color-body-fixed);
            &:before {
                content: "";
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 35px;
                height: 2px;
                background-color: var(--color-body-fixed);
            }
        }
    }

    .tag-share-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 40px;
        gap: 20px;
        flex-wrap: wrap;
        .tagcloud {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 10px;
            .tag-cloud-link {
                display: flex;
                align-items: center;
                column-gap: 5px;
                line-height: 1;
                border-radius: 5px;
                padding: 7px 12px 4px;
                background-color: transparent;
                border: 1px solid;
                border-color: var(--color-border-dark-1);
                color: var(--color-dark-1);
                transition: var(--transition);
                .icon-holder {
                    line-height: 1;
                }
                &:hover {
                    background-color: var(--color-primary);
                    border-color: var(--color-primary);
                    color: var(--color-light-1-fixed);
                }
            }
        }
        .axil-social {
            margin-bottom: 0;
        }  
    }

    .author-wrap {
        margin-bottom: 40px;
        padding: 95px 100px 30px;
        border-radius: 8px;
        text-align: center;
        position: relative;
        margin-top: 90px;
        @media (max-width: 767px) {
            padding: 95px 30px 30px;
        }
        @media (max-width: 575px) {
            padding: 95px 15px 30px;
        }

        .author-thumb {
            max-width: 120px;
            width: 100%;
            margin: 0 auto 20px;
            position: absolute;
            top: -40px;
            left: 0;
            right: 0;
            border: 1px solid;
            border-color: var(--color-border-dark-1);
            border-radius: 50%;
            img {
                border-radius: 50%;
            }
        }

        .author-content {
            .entry-title {
                margin-bottom: 5px;
                font-size: 20px;
            }

            .author-designation {
                margin-bottom: 10px;
                font-size: 16px;
                font-weight: 500;
                color: var(--color-dark-1-fixed);
            }
            .axil-social {
                margin-bottom: 0;
                ul {
                    justify-content: center;
                }
            }
        }
    }

    .post-navigation {
        display: flex;
        justify-content: space-between;
        margin-bottom: 40px;
        padding-bottom: 40px;
        padding-top: 40px;
        border-width: 1px 0;
        border-style: solid;
        border-color: var(--color-border-dark-1);

        .post-box {  
            flex: 1; 
            display: flex;
            align-items: center;
            column-gap: 15px;
            .figure-holder {
                max-width: 90px;
                width: 100%;
                .link-wrap {
                    border-radius: 50%;
                }
                img {
                    border-radius: 50%;
                }
                @media (max-width: 575px) {
                    display: none;
                }
            }      
            .entry-title {
                margin-bottom: 0;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;  
                overflow: hidden;
            }
            .text-box {
                font-size: 14px;
                margin-bottom: 5px;
                display: inline-flex;
                align-items: center;
                column-gap: 5px;
                color: var(--color-dark-1);
                transition: var(--transition);
                &:hover {
                    color: var(--color-primary);
                }
                .icon-holder {
                    font-size: 20px;
                    line-height: 1;
                }
            }
            &.prev-post {
                margin-right: 15px;
                position: relative;
                &:before {
                    content: "";
                    height: 50px;
                    width: 1px;
                    background-color: var(--color-border-dark-1);
                    position: absolute;
                    right: -15px;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
            &.next-post {
                text-align: right;
                margin-left: 15px;
                flex-direction: row-reverse;
            }
        }
    }

    .leave-comment {
        p {
            font-size: 15px;
        }
        .leave-form-box {    
            textarea.form-control,
            input.form-control {
                color: var(--color-dark-1);
                border: 1px solid;
                border-color: var(--color-border-dark-1);       
                margin-bottom: 12px;
                padding-left: 20px;
                border-radius: 8px;
                background-color: var(--color-light-2);
                transition: var(--transition);
        
                &:focus {
                    color: var(--color-dark-1);
                    box-shadow: none;
                    background-color: var(--color-light-2);
                    border-color: var(--color-border-dark-1);
                }
            }
        
            input.form-control {
                height: 50px;
                @media (max-width: 767px) {
                    margin-bottom: 0;
                }
            }
            
            textarea.form-control {
                height: 180px;
                padding: 15px 20px;
            }
        
            input::-webkit-input-placeholder {
                font-size: 15px;
                color: var(--color-meta-dark-1);
                transition: var(--transition);
            }
        
            input:-ms-input-placeholder {
                font-size: 15px;
                color: var(--color-meta-dark-1);
                transition: var(--transition);
            }
        
            input:-moz-placeholder {
                font-size: 15px;
                color: var(--color-meta-dark-1);
                transition: var(--transition);
            }
        
            input[type="checkbox"] {
                position: relative;
                background-color: transparent;
                margin: 0;
                font: inherit;
                color: var(--color-border-2);
                width: 14px;
                height: 14px;
                border: 1.5px solid var(--color-border-2);
                border-radius: 1px;
                transform: translateY(2px);
                transition: var(--transition);
            }
        
            .show-message-label {
                color: var(--color-meta-dark-1);
                font-size: 15px;
                font-weight: 400;
                margin-left: 2px;
                transition: var(--transition);
            }
        }
    }

    .post-comment {
        ul {
            padding: 0;
            margin: 0;
            list-style: none;

            li {
                .each-comment {
                    position: relative;
                    display: flex;
                    gap: 20px;
                    margin-bottom: 20px;

                    .comment-figure {
                        width: 100px;
                        display: table;

                        img {
                            border-radius: 50%;
                        }
                    }

                    .comment-content {
                        flex: 1;

                        .comment-title {
                            margin-bottom: 5px;
                            font-size: 18px;
                        }

                        .comment-meta {
                            margin-bottom: 12px;
                            font-size: 15px;

                            .post-date {
                                color: var(--color-meta-dark-1);
                            }

                        }
                        .item-btn {
                            position: absolute;
                            top: 0;
                            right: 0;
                            background-color: var(--color-light-2);
                            color: var(--color-dark-1);
                            padding: 3px 12px;
                            border-radius: 4px;
                            font-size: 12px;
                            font-weight: 500;
                            transition: var(--transition);

                            &:hover {
                                background-color: var(--color-primary);
                                color: var(--color-light-1-fixed);
                            }
                            @media (max-width: 575px) {
                                position: inherit;
                            }
                        }

                        .comment-comment {
                            color: var(--color-dark-1);
                        }                    
                    }
                }

                .children {
                    padding-left: 50px;
                }
            }
        }
    }  

    &.content-layout2 {
        position: relative;
        margin-bottom: 40px;
        .banner-figure-holder {
            position: relative;  
            height: 400px;          
            &:before {
                position: absolute;
                z-index: 0;
                top: 0;
                left: 0;
                right: 0;
                content: "";
                height: 100%;
                width: 100%;
                background: linear-gradient(360deg, #020112 0%, rgba(52, 51, 59, 0.55) 40.78%, rgba(217, 217, 217, 0.00) 100%);
            }
            img {
                height: 100%;
                object-fit: cover;
                border-radius: 0;
            }
            @media (min-width: 768px) {
                height: 450px;
            }
            @media (min-width: 992px) {
                height: 500px;
            }
            @media (min-width: 1200px) {
                height: 600px;
            }
            @media (min-width: 1300px) {
                height: 700px;
            }
        }
        .mask-content {
            z-index: 1;
            position: absolute;
            bottom: 50px;            
            padding-left: 12px;
            padding-right: 12px;
            width: 100%;
            left: 0;
            right: 0;
            margin: 0 auto;
            @media (min-width: 576px) {
                max-width: 540px;
            }
            @media (min-width: 768px) {
                max-width: 720px;
            }
            @media (min-width: 992px) {
                max-width: 960px;
            }
            @media (min-width: 1200px) {
                max-width: 1140px;
            }
            @media (min-width: 1300px) {
                max-width: 1254px;
            }
            .entry-meta {
                margin-bottom: 25px;
            }
            .axil-social {
                margin-bottom: 0;
            }
        }
    } 

    &.content-layout3 {
        margin-bottom: 40px;
    }
     
    &.content-layout5 {
        padding: 60px 0;
        @media (max-width: 767px) {
            padding: 50px 0;
        }
        .mask-content {           
            padding-left: 12px;
            padding-right: 12px;
            width: 100%;
            margin: 0 auto;
            @media (min-width: 576px) {
                max-width: 540px;
            }
            @media (min-width: 768px) {
                max-width: 720px;
            }            
            @media (min-width: 992px) {
                max-width: 960px;
            }
            @media (min-width: 1200px) {
                max-width: 1140px;
            }
            @media (min-width: 1300px) {
                max-width: 1254px;
            }
            .entry-meta {
                margin-bottom: 25px;
            }
            .axil-social {
                margin-bottom: 0;
            }
        }
    }  
}




